import request from '@/util/request'
// 抵佣金明细列表
export function memberCreditList(params) {
  return request({
    url: '/admin/v1/marketing/memberCreditList',
    method: 'get',
    params
  })
}
//抵佣金设置
export function marketingTask(params) {
  return request({
    url: '/admin/v1/marketing/task',
    method: 'get',
    params
  })
}
// 设置奖励
export function setTask(data) {
  return request({
    url: '/admin/v1/marketing/setTask',
    method: 'post',
    data
  })
}
// 改变可用状态
export function changeTaskStatus(data) {
  return request({
    url: '/admin/v1/marketing/changeTaskStatus',
    method: 'post',
    data
  })
}
